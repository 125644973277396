import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getListingsAggsPriceAnnoncements = (filter: Record<string, any>) => {
  return client.post(
    '/listing/price-annoncement',
    filter,
    filter?.signal && { signal: filter.signal },
  )
}

const estimateMarketService = { getListingsAggsPriceAnnoncements }

export default estimateMarketService
