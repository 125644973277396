/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { isAfter, isBefore } from 'date-fns'
import { unionArray } from '../../../utils'
import listingService from './listingHistoryService'

const currentYear = new Date().getFullYear()

const initialState: any = {
  rentData: {},
  buyData: {},
  listings: [],
  selectedListings: [],
  favoriteListing: [],
  geojson: null,
  listingsById: {},
  listingBaseInfo: [],
  addressesLoading: false,
  noLayers: false,
  aggregations: {
    dates: {
      dates: {
        min_as_string: '2005-01-01',
        max_as_string: currentYear.toString(),
      },
    },
    regions: { regions: { buckets: [] } },
    zones: { zones: { buckets: [] } },
    cities: { cities: { buckets: [] } },
    vendor_type: { vendor_type: { buckets: [] } },
  },
  features: {},
  total: 0,
  count: 0,
  totalFeatures: 0,
  listingsFavoriteDashboard: [],
  aggregationLoading: false,
  propertyCategoryOptions: [],
  propertyTypes: {
    house: [],
  },
  loading: false,
  bbox: undefined,
  propertyTypeInitState: {},
  pendingRequestCount: 0,
  vendorTypeOptions: [],
  pendingRequestCountLocation: 0,
  aggregationLocationLoading: false,
  aggregationsLocations: {
    regions: { regions: { buckets: [] } },
    zones: { zones: { buckets: [] } },
    cities: { cities: { buckets: [] } },
    npas: { npas: { buckets: [] } },
  },
  propertyTypesLoading: false,
  listingsTileDetailsLoading: false,
  listingsTileDetails: [],
}

export const getListings = createAsyncThunk(
  'listingHistory/getListings',
  listingService.getListings,
)

export const getSelectedListings = createAsyncThunk(
  'listingHistory/getSelectedListings',
  listingService.getSelectedListings,
)

export const getAggregationsListings = createAsyncThunk(
  'listingHistory/getAggregationsListings',
  listingService.getAggregationsListings,
)

export const getListingsStats = createAsyncThunk(
  'listingHistory/getListingsStats',
  listingService.getListingStats,
)

export const getComparedListingsStats = createAsyncThunk(
  'listingHistory/getComparedListingStats',
  listingService.getListingStats,
)

export const countListings = createAsyncThunk(
  'listingHistory/countListings',
  listingService.countListings,
)

export const getPlotsFromListing = createAsyncThunk(
  'listingHistory/getPlotsFromListing',
  listingService.getPlotsFromListing,
)

export const getLocationsAggregations = createAsyncThunk(
  'listingHistory/getLocationsAggregations',
  listingService.getLocationsAggregations,
)

export const saveFavoriteListing = createAsyncThunk(
  'listingHistory/saveFavoriteListing',
  async ({ values, callback }: { values: any; callback: () => void }) => {
    const res = await listingService.saveFavoriteListing(values)

    callback()

    return res
  },
)

export const getFavoriteListing = createAsyncThunk(
  'listingHistory/getFavoriteListing',
  listingService.getFavoriteListing,
)

export const activeOrDesactivateFavoriteListing = createAsyncThunk(
  'listingHistory/activeOrDesactivateFavoriteListing',
  listingService.activeOrDesactivateFavoriteListing,
  // async ({ values, callback }: { values: any; callback: () => void }) => {
  //   const res = await listingService.activeOrDesactivateFavoriteListing(values)

  //   callback()

  //   return res
  // },
)

export const getPropertyTypeThunk = createAsyncThunk(
  'listingHistory/getPropertyType',
  async ({ category, filter }: { category: string; filter?: any }) => {
    const res = await listingService.getAggregationsPropertyListings({
      propertyCategory: [category],
      ...filter,
    })

    return {
      data: res,
      categoryKey: (category || '').replaceAll(' ', '_').toLowerCase(),
    }
  },
)

export const getListingsTileDetails = createAsyncThunk(
  'listingHistory/getListingsTileDetails',
  listingService.getListingsTileDetailsRedux,
)

export const listingHistory = createSlice({
  name: 'listingHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListings.pending, (state) => {
        state.loading = true
      })
      .addCase(getListings.rejected, (state) => {
        state.loading = false
      })
      .addCase(getListings.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.loading = false

        if (data) {
          state.listings = data.result || [{ ...data.data, id: data.id }]

          state.bbox = data.bbox
        }
      })
      .addCase(getSelectedListings.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.selectedListings = data.result || [
            { ...data.data, id: data.id },
          ]
        }
      })
      .addCase(getAggregationsListings.pending, (state) => {
        state.aggregationLoading = true
      })
      .addCase(getAggregationsListings.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const aborted = !!action.meta.arg.signal?.aborted

        if (data) {
          state.aggregations = {
            // ...initialState.aggregations,
            ...data.aggregations,
          }
          state.propertyCategoryOptions = unionArray(
            state.propertyCategoryOptions || [],
            data.aggregations?.property_category?.property_category?.buckets ||
              [],
            'key',
          )
          state.vendorTypeOptions = unionArray(
            state.vendorTypeOptions || [],
            data.aggregations?.vendor_type?.vendor_type?.buckets || [],
            'key',
          )
        }
        if (!aborted) {
          state.aggregationLoading = false
        }
      })
      .addCase(getAggregationsListings.rejected, (state) => {
        state.aggregationLoading = false
      })
      .addCase(getListingsStats.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) state.priceData = data
      })
      .addCase(getComparedListingsStats.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) state.comparePriceData = data
      })
      .addCase(countListings.fulfilled, (state, action) => {
        const { data = 0 } = action.payload || {}
        const { arg } = action.meta

        state.count = arg?.id?.length > 0 ? arg.id?.length : data
      })
      .addCase(getPlotsFromListing.pending, (state) => {
        state.addressesLoading = true
        state.features = {}
      })
      .addCase(getPlotsFromListing.rejected, (state) => {
        state.addressesLoading = false
        state.features = {}
      })
      .addCase(getPlotsFromListing.fulfilled, (state, action) => {
        const { data } = action.payload

        state.addressesLoading = false
        state.features = data.result
        state.noLayers = data.total.value === 0
      })
      .addCase(getFavoriteListing.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.favoriteListing = data.map((listing: any) => ({
            ...listing,
            geo_center: `POINT(${
              JSON.parse(listing?.geo_center)?.coordinates?.[0]
            } ${JSON.parse(listing?.geo_center)?.coordinates?.[1]}`,
          }))
        }
      })
      .addCase(saveFavoriteListing.fulfilled, (state, action) => {
        const { data } = action.payload

        if (data) {
          state.listings = state.listings.map((listing: any) => {
            if (listing.id === Number(data.listingId)) {
              return {
                ...listing,
                favorite: true,
                address: data.address,
                geo_center: `POINT(${data?.geo_center?.coordinates?.[0]} ${data?.geo_center?.coordinates?.[1]}`,
                address_id: data.id,
              }
            }

            return listing
          })
        }
      })
      .addCase(getPropertyTypeThunk.pending, (state) => {
        state.propertyTypesLoading = true
      })
      .addCase(getPropertyTypeThunk.fulfilled, (state, { payload }) => {
        const { data, categoryKey } = payload

        state.propertyTypesLoading = false
        if (data?.data) {
          state.propertyTypes[categoryKey] =
            data.data.aggregations?.property_type?.property_type?.buckets || []

          state.propertyTypeInitState[categoryKey] = true
        }
      })
      .addCase(getPropertyTypeThunk.rejected, (state) => {
        state.propertyTypesLoading = false
      })
      .addCase(
        activeOrDesactivateFavoriteListing.fulfilled,
        (state, { meta }) => {
          const input = meta.arg

          if (input.listingId) {
            state.listings = state.listings.map((listing: any) => {
              if (Number(listing.id) === Number(input.listingId)) {
                return { ...listing, favorite: !input.active }
              }

              return listing
            })
          }
        },
      )
      .addCase(getLocationsAggregations.pending, (state) => {
        state.aggregationLocationLoading = true
      })
      .addCase(getLocationsAggregations.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.aggregationsLocations = {
            ...initialState.aggregationsLocations,
            ...data.aggregations,
          }
        }
        state.aggregationLocationLoading = false
      })
      .addCase(getLocationsAggregations.rejected, (state) => {
        state.aggregationLocationLoading = false
      })
      .addCase(getListingsTileDetails.pending, (state) => {
        state.listingsTileDetailsLoading = true
      })
      .addCase(getListingsTileDetails.fulfilled, (state, action) => {
        const { data } = action.payload || []

        if (data) {
          const sortData = data?.sort((prev: any, next: any) => {
            if (
              prev?.listing_timestamp &&
              next?.listing_timestamp &&
              isBefore(
                new Date(prev?.listing_timestamp),
                new Date(next?.listing_timestamp),
              )
            ) {
              return 1
            }

            if (
              prev?.listing_timestamp &&
              next?.listing_timestamp &&
              isAfter(
                new Date(prev?.listing_timestamp),
                new Date(next?.listing_timestamp),
              )
            ) {
              return -1
            }

            return 0
          })

          state.listingsTileDetails = sortData
        }
        state.listingsTileDetailsLoading = false
      })
      .addCase(getListingsTileDetails.rejected, (state) => {
        state.listingsTileDetailsLoading = false
      })
  },
})

export const listingHistoryReducer = {
  listingHistory: listingHistory.reducer,
}
